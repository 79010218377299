import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import i18n from '../../i18n';
import { getReviewConfig } from '../../services/reviewConfig';
import { createInitialAsyncState } from '../utils';
import { retrieveToken } from '../../utils/token-utils';
import { ReviewConfig } from '../../models/ReviewConfig';
import { ApiError } from '@yumpingo/yummy-components';

export const fetchReviewConfig = createAsyncThunk('reviewConfig/fetchReviewConfig', async (_, thunkAPI) => {
  const token = retrieveToken();
  if (!token) {
    return thunkAPI.rejectWithValue({ message: 'Missing token' });
  }
  try {
    const response = await getReviewConfig(token, i18n.resolvedLanguage);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue((e as ApiError).json);
  }
});

const reviewConfigSlice = createSlice({
  name: 'reviewConfig',
  initialState: createInitialAsyncState<ReviewConfig>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewConfig.pending, (state) => {
        state.isFetching = true;
        state.didInvalidate = false;
        state.error = undefined;
        state.data = undefined;
      })
      .addCase(fetchReviewConfig.fulfilled, (state, action) => {
        state.isFetching = false;
        state.didInvalidate = false;
        state.error = undefined;
        state.data = action.payload;
      })
      .addCase(fetchReviewConfig.rejected, (state, action) => {
        state.data = undefined;
        state.isFetching = false;
        state.didInvalidate = true;
        state.error = action.payload as SerializedError;
      });
  },
});

export default reviewConfigSlice.reducer;
